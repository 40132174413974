import React from 'react';

const Header = () => {
    return ( 
        <header className="bg-alert">
            <h1>Busca Recetas de Bebidas</h1>
            <h2 style={{textAlign:'center',color:'#e83e8c'}}>srojasweb.dev</h2>
        </header>
     );
}
 
export default Header;